import L_Handle_set from "../img/bot1.avif";
import Fat_Grip_For_Cupping from "../img/bot_plast.jpg";
import Cone_Grip from "../img/bot_printed.jpg";
import Wallet from "../img/wallet.jpg";
import belt from "../img/belt.jpg";
import watch_women from "../img/watch.jpg";
import watch_men from "../img/watch_2.jpg";
import wallet_women from "../img/wallet_women.jpg";
import cardholder_1 from "../img/cardholder_1.jpg";
import cardholder_2 from "../img/cardholder_2.jpg";
import phonecase_1 from "../img/phonecase_1.jpg";
import hand_1 from "../img/hand_1.jpg";
import hand_2 from "../img/hand_2.jpg";
import keyring_1 from "../img/keyring_1.jpg";
import keyring_2 from "../img/keyring_2.jpg";
import keyring_3 from "../img/keyring_3.jpg";
import candle_1 from "../img/candle_2.jpg";
import candle_2 from "../img/candle_3.jpg";
import Cupping_Handle from "../img/Cupping_Handle.png";
import Multi_Spinner from "../img/Multi_Spinner.png";
import TRINGA_ECCENTRIC_SET from "../img/TRINGA_ECCENTRIC_SET.png";
import Palm_Cupping_L_Handle from "../img/Palm_Cupping_L_Handle.png";
import Loading_Pin from "../img/Loading_Pin.png";
import Hand_Winder from "../img/Hand_Winder.png";
import Tiranga_Eccentric_Grip from "../img/Tiranga_Eccentric_Grip.png";
import Elliptical_Wrist_Ball from "../img/Elliptical_Wrist_Ball.png";
import Premium_Table_Strap from "../img/Premium_Table_Strap.png";
import Eccentric_Grip from "../img/Eccentric_Grip.png";
import Professional_Wrist_Ball from "../img/Professional_Wrist_Ball.png";
import Devon_Larrat_Cupping_Handles from "../img/Devon_Larrat_Cupping_Handles.png";
import Pronation_Ring_with_Hook from "../img/Pronation_Ring_with_Hook.png";
import Arm_Wrestling_Training_Strap from "../img/Arm_Wrestling_Training_Strap.png";
import Stainless_Steel_15mm_C_Handle from "../img/Stainless_Steel_15mm_C_Handle.png";
import Arm_Wrestling_Handles_Set from "../img/Arm_Wrestling_Handles_Set.png";
import Claws_Arm_Wrestling_Table from "../img/Claws_Arm_Wrestling_Table.png";
import Arm_Wrestling_Table_Pulley from "../img/Arm_Wrestling_Table_Pulley.png";
import Professional_Training_Strap from "../img/Professional_Training_Strap.png";
import Claws_Cotton_Denis_T_Shirt_Black from "../img/Claws_Cotton_Denis_T-Shirt_Black.png";
import product1 from "../img/product-1.JPG";
import cups_1 from "../img/cups_1.jpg";
import cups_2 from "../img/cups_2.jpg";
import cups_3 from "../img/cups_3.jpg";
import cups_4 from "../img/cups_4.jpg";
import cups_5 from "../img/cups_5.jpg";
import bags_1 from "../img/bags_1.jpg";
import bags_2 from "../img/bags_2.jpg";

import bags_3 from "../img/bags_3.webp";

import bags_4 from "../img/marvel.jpg";









const Cardsdata = [
    {
        id: 1,
        rname: "Bottle",
        imgdata: L_Handle_set,
        somedata: "L Grip, AP Cone Grip 70mm, Fat Grip 50mm, Normal Grip 30mm, C Handle",
        price: 4499,
        rating: "3.8",
        qnty:0,
        category:"bottles",
    },
    {
        id: 2,
        rname: "Plastic Bottle",
        imgdata: Fat_Grip_For_Cupping,
        somedata: "50mm Fat Grip for Cupping Fits with Wristball,net Handle",
        price: 599,
        rating: "3.8",
        qnty:0,
        category:"bottles",
    },
    {
        id: 3,
        rname: "Printed Bottle",
        imgdata: Cone_Grip,
        somedata: "70mm Cone Grip, Best for Hook, Top Roll Training",
        price: 599,
        rating: "3.8",
        qnty:0,
        category:"bottles",
    },
    {
        id: 4,
        rname: "Wallet",
        imgdata: Wallet,
        somedata: "High Pressure, PVC Pipe, 2 inch Diameter",
        price: 699,
        rating: "3.8",
        qnty:0,
        category:"Equipment",
    },
    {
        id: 5,
        rname: "Belt",
        imgdata: belt,
        somedata: "High Pressure, PVC Pipe, 2 inch Diameter",
        price: 458,
        rating: "4.0",
        qnty:0,
        category:"Equipment",
    },
    {
        id: 6,
        rname: "Watch",
        imgdata: watch_women,
        somedata: "High Pressure, PVC Pipe, 2 inch Diameter",
        price: 12999,
        rating: "4.9",
        qnty:0,
        category:"watch",
    },
    {
        id: 7,
        rname: "Watch",
        imgdata: watch_men,
        somedata: "70mm Eccentric Grip, Best for finger training",
        price: 10999,
        rating: "5.0",
        qnty:0,
        category:"watch",
    },
    {
        id: 8,
        rname: "Wallet",
        imgdata: wallet_women,
        somedata: "65mm Size and 80kg Capacity, Professional wrist ball for supination, pronation and raising training",
        price: 1599,
        rating: "4.2",
        qnty:0,
        category:"Equipment",
    },
    {
        id: 9,
        rname: "Card Holder",
        imgdata: cardholder_1,
        somedata: "Devon Larrat Cupping Handles, Set of 3- Wrist Wrench, Multi Spinner , Cupping Handle",
        price: 699,
        rating: "4.4",
        qnty:0,
        category:"Equipment",
    },
    {
        id: 10,
        rname: "Card Holder",
        imgdata: cardholder_2,
        somedata: "Thick Chain, Heavy Duty Pronation, Ring Max Load 200kg",
        price: 799,
        rating: "4.8",
        qnty:0,
        category:"Equipment",
    },
    {
        id: 11,
        rname: "Phone case",
        imgdata: phonecase_1,
        somedata: "Fine and Thick Quality, Strap for Light Weight Exercise and Recovery, Maximum Load- 70kg",
        price: 199,
        rating: "3.8",
        qnty:0,
        category:"Equipment",
    },
    {
        id: 12,
        rname: "Hankerchief",
        imgdata: hand_1,
        somedata: "Fits With all Products of Wristball,net",
        price: 199,
        rating: "3.8",
        qnty:0,
        category:"Equipment",
    },
    {
        id: 13,
        rname: "Hankerchief",
        imgdata: hand_2,
        somedata: "Fits With all Products of Wristball,net",
        price: 199,
        rating: "3.8",
        qnty:0,
        category:"Equipment",
    },
    {
        id: 14,
        rname: "Keyring",
        imgdata: keyring_1,
        somedata: "70mm Cone Grip, 50mm Fat Grip, 70mm Eccentric Grip, 30mm Normal Grip, C Handle",
        price: 4499,
        rating: "3.8",
        qnty:0,
        category:"Equipment",
    },
   
    {
        id: 15,
        rname: "Keyring",
        imgdata: keyring_2,
        somedata: "Fits on any Arm, Wrestling Table",
        price: 1999,
        rating: "3.8",
        qnty:0,
        category:"Equipment",
    },
    {
        id: 16,
        rname: "Rose Candle",
        imgdata: candle_1,
        somedata: "2 inch wide, very thick and fine quality training strap, maximum loading capacity is 150kg",
        price: 299,
        rating: "3.8",
        qnty:0,
        category:"Equipment",
    },
    {
        id: 17,
        rname: "Lavendar Candle",
        imgdata: candle_2,
        somedata: "Cotton Black Coloured, Denis Printed T-Shirt, for Arm Wrestler Lovers",
        price: 899,
        rating: "3.8",
        qnty:0,
        category:"Merchandise",
    },
    {
        id: 18,
        rname: "Keyring",
        imgdata: keyring_3,
        somedata: "Cotton Black, Coloured Claws Logo Printed, T-Shirt for Arm Wrestler Lovers",
        price: 499,
        rating: "3.8",
        qnty:0,
        category:"Merchandise",
    },
    {
        id: 19,
        rname: "Cups",
        imgdata: cups_1,
        somedata: "Cotton Black, Coloured Claws Logo Printed, T-Shirt for Arm Wrestler Lovers",
        price: 499,
        rating: "3.8",
        qnty:0,
        category:"cups",
    },
    {
        id: 20,
        rname: "Printed cups",
        imgdata: cups_2,
        somedata: "Cotton Black, Coloured Claws Logo Printed, T-Shirt for Arm Wrestler Lovers",
        price: 499,
        rating: "3.8",
        qnty:0,
        category:"cups",
    },
    {
        id: 21,
        rname: "Cup handle",
        imgdata: cups_3,
        somedata: "Cotton Black, Coloured Claws Logo Printed, T-Shirt for Arm Wrestler Lovers",
        price: 499,
        rating: "3.8",
        qnty:0,
        category:"cups",
    },
    {
        id: 22,
        rname: "Coffee cup",
        imgdata: cups_4,
        somedata: "Cotton Black, Coloured Claws Logo Printed, T-Shirt for Arm Wrestler Lovers",
        price: 499,
        rating: "3.8",
        qnty:0,
        category:"cups",
    },
    {
        id: 23,
        rname: "Casual Bag",
        imgdata: bags_1,
        somedata: "Cotton Black, Coloured Claws Logo Printed, T-Shirt for Arm Wrestler Lovers",
        price: 499,
        rating: "3.8",
        qnty:0,
        category:"bags",
    },
    {
        id: 24,
        rname: "Printed Bags",
        imgdata:  bags_3,
        somedata: "Cotton Black, Coloured Claws Logo Printed, T-Shirt for Arm Wrestler Lovers",
        price: 499,
        rating: "3.8",
        qnty:0,
        category:"bags",
    },
    {
        id: 25,
        rname: "School Bag",
        imgdata: bags_2,
        somedata: "Cotton Black, Coloured Claws Logo Printed, T-Shirt for Arm Wrestler Lovers",
        price: 499, 
        rating: "3.8",
        qnty:0,
        category:"bags",
    },
    {
        id: 26,
        rname: "Marvel Bags",
        imgdata: bags_4,
        somedata: "Cotton Black, Coloured Claws Logo Printed, T-Shirt for Arm Wrestler Lovers",
        price: 499,
        rating: "3.8",
        qnty:0,
        category:"bags",
    },

    {
        id: 27,
        rname: "Cup ",
        imgdata: cups_5,
        somedata: "Cotton Black, Coloured Claws Logo Printed, T-Shirt for Arm Wrestler Lovers",
        price: 499,
        rating: "3.8",
        qnty:0,
        category:"cups",
    },

];

export default Cardsdata;