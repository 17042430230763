import React from 'react';
import "../Style/footer.css";
import { IoLogoWhatsapp } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { CDBFooter, CDBFooterLink, CDBBox, CDBBtn, CDBIcon } from 'cdbreact';
import img from "../img/logo.png";

function Footer() {

    const phoneNumber1 = '+919582499831'; // Replace with your WhatsApp number
    const message = 'Hello sir!'; // Replace with your message
  
    // Function to format the WhatsApp URL
    const getWhatsAppLink = () => {
      return `https://wa.me/${phoneNumber1}?text=${encodeURIComponent(message)}`;
    };
  
    // Function to handle button click
    const handleWhatsAppButtonClick = () => {
      window.open(getWhatsAppLink(), '_blank');
    };


    const username = 'clawsarmwrestling.in'; // Replace with your Instagram username

  // Function to get the Instagram URL
  const getInstagramLink = () => {
    return `https://www.instagram.com/${username}`;
  };
  const handleInstagramButtonClick = () => {
    window.open(getInstagramLink(), '_blank');  
  };


  const phoneNumber = '+919999999999'; // Replace with your phone number
  const phoneNumber2 = '+91999999999'; // Replace with your phone number

  // Function to handle button click
  const handlePhoneButtonClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };
  const handle = () => {
    window.location.href = `tel:${phoneNumber2}`;
  };

  return (
    <div className='Footer_container' id='footer' >
        {/* <div className="continer pt-4 bg-light d-flex flex-wrap justify-content-center align-items-center" style={{backgroundColor:'blue !important'}}>
            <div className='footer_subcon1'>
                <h4>Get In Touch</h4>
                <p><i className='fa fa-home'></i>  Ghaziabad, Uttar Pradesh</p>
                <p  style={{cursor:'pointer'}}><i className='fa fa-phone'></i> +91 9999999999</p>
                <p style={{cursor:'pointer', marginTop:'-10px'}} ><i className='fa fa-phone'> </i> +91 999999999</p>
            </div>
            <div className='footer_subcon2'>    
                        <h4>Follow Us</h4>
                        <div className='d-flex justify-content-center align-items-center flex-wrap'>
                    <div className='footer_subcon2_logo'>
                        <a href="" style={{color:"white"}}>
                        <IoLogoWhatsapp   style={{fontSize:"2.5rem",marginRight:'0.5rem'}}/>
                        </a>
                    </div>
                    <div className='footer_subcon2_logo'>
                        <a href="" style={{color:"white"}}>
                        <FaInstagram   style={{fontSize:"2.5rem",margin:'0.5rem'}}/>
                        </a>
                    </div>
                    <div className='footer_subcon2_logo'>
                        <a href="https://www.youtube.com/@kaushikshiv?si=f7-elaRQdsyLGOjG" style={{color:"white"}}>
                        <FaYoutube  style={{fontSize:"2.5rem",margin:'0.5rem'}}/>
                        </a>
                    </div>
                        </div>
            </div>
            <div className='footer_subcon3'>
                <h4>Company Info</h4>
                <p className='footer_para_heading'><a href="">
                <i class="fa fa-cube mx-1 " style={{color:'white'}} aria-hidden="true"></i>
                <Link to='/about-us'  > About Us
                 
                </Link>   
                    </a>
                     </p>
                <p className='footer_para_heading'><a href="">
                <i class="fa fa-cube mx-1 " style={{color:'white'}} aria-hidden="true"></i>
                  <Link to='/privacy-policy'>
                      Privacy Policy
                    </Link> 
                    </a>
                     </p>
                <p className='footer_para_heading'><a href="">
                <i class="fa fa-cube mx-1 " style={{color:'white'}} aria-hidden="true"></i>
                <Link to='/terms-policies'>
                     Terms & Conditions
                    </Link>  


                    </a>
                     </p>

            </div>
            <div className='footer_subcon4'>
            <h4>Purchase Info</h4>
                <p className='footer_para_heading'>
                <i class="fa fa-cube mx-1 " style={{color:'white'}} aria-hidden="true"></i>
                    <a href="">
                    <Link to='/shipping-policy'>
                    Shipping Policy</Link>        
                    </a>
                    </p>
                <p className='footer_para_heading'>
                <i class="fa fa-cube mx-1"  style={{color:'white'}}aria-hidden="true"></i>
                    <a href="">
                    <Link to='/payment-policies'>
                    Payment Policy</Link>    
                    </a>
                    </p>
                <p className='footer_para_heading'>
                <i class="fa fa-cube mx-1 " style={{color:'white'}} aria-hidden="true"></i>
                    <a href="">
                    <Link to='/return-policy'>
                    Return Policy</Link>    
                    </a>
                    </p>

            </div>
        </div> */}
       
        
       <footer className=" text-white mt-5 p-4 text-start" style={{backgroundColor:'#374151' }} >
      <div className="container">
        <div className="row">
          <div className="col-md-5">
          <img
                  style={{ height: "60px", width: "170px", marginLeft:'-18px' }}
                  src={img}
                  alt="Logo"
                  className="img-fuild"
                />

    

            <p>
            We are a top-tier e-commerce platform committed to delivering an unparalleled shopping experience, offering a selection of high-quality products at unbeatable prices. Whether you're searching for essentials or specialty items, we ensure the best value without compromising on quality.
            </p>
          </div>
          <div className="col-md-3 ps-5 pt-2 ">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><Link to="/" className="text-white text-start">Home</Link></li>
              <li><Link to="/Product_details" className="text-white">Shop</Link></li>
              <li><Link to="/about-us" className="text-white">About</Link></li>
              <li><Link to="/privacy-policy" className="text-white">Privacy & Policy</Link></li>
              <li><Link to="/shipping-policy" className="text-white">Shipping Policy</Link></li>


            </ul>
          </div>
          <div className="col-md-4 pt-2">
            <h5>Contact Us</h5>
            <p>
              Email: business@hashbug.in
              <br />
              Phone: +91 8127133951
              <br />
              Address: 3rd Floor, BPTP Center One, Sector 61, Golf Course Extension
              Road, Gurugram, Haryana   
      
            </p>
            {/* <div>
              <a href="#" className="text-white me-3">
                <i className="fab fa-facebook"></i>
              </a>
              <a href="#" className="text-white me-3">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#" className="text-white">
                <i className="fab fa-instagram"></i>
              </a>
            </div> */}
          </div>
        </div>
        <hr className="bg-light" />
        <p className="mb-0">&copy; 2024 HASHBUG. All Rights Reserved.</p>
      </div>
    </footer>

        

    </div>
  )
}

export default Footer